<template>
    <!--begin::Dashboard-->
    <div class="card card-custom gutter-b col-lg-13 col-xxl-12 mt-5 p-5">
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <div
                    class="d-flex align-items-center flex-row justify-content-between mb-5 pb-5 border-secondary border-bottom">
                    <b-skeleton type="button" width="12%"></b-skeleton>
                    <b-skeleton type="input" width="40%"></b-skeleton>
                    <span></span>
                </div>
                <div class=" mt-5 d-flex flex-column justify-content-around">
                    <div class=" mt-5 d-flex flew-row justify-content-between align-items-center">
                        <div class="mt-5" style="width:70%">
                            <b-skeleton width="85%"></b-skeleton>
                            <b-skeleton type="input" width="85%"></b-skeleton>
                        </div>
                        <div class="mr-10 mt-5" style="width:30%">
                            <b-skeleton width="95%"></b-skeleton>
                            <b-skeleton type="input" width="95%"></b-skeleton>
                        </div>
                    </div>
                    <div class="mt-10" style="width:100%">
                        <b-skeleton></b-skeleton>
                        <b-skeleton class="mt-2" type="input"></b-skeleton>
                    </div>
                </div>
                <div class="mt-15 d-flex justify-content-center">
                    <b-skeleton class="mr-3" type="button"></b-skeleton>
                    <b-skeleton type="button"></b-skeleton>
                </div>
            </template>
            <div
                class="d-flex align-items-center flex-row justify-content-between mb-5 pb-5 border-secondary border-bottom">
                <router-link :to="{ name: 'lessonItems/editor', params: { id: this.$route.params.id } }" replace>
                    <span class="btn btn-outline-danger btn-md">
                        <span class="svg-icon svg-icon-md svg-icon-light-danger">
                            <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                        </span>
                        {{ $t('CONTROLLERS.CANCEL') }}
                    </span>
                </router-link>
                <h2 class="h2 mt-5">Modification du cours : {{ title }}</h2>
                <span></span>
            </div>
            <!--begin::Form-->
            <b-form v-if="show" class="my-5 px-5">
                <div class="d-flex flex-column justify-content-around">
                    <div class="d-flex flew-row justify-content-between align-items-center">
                        <b-form-group id="input-group-title" :label="$t('LESSON.TITLE.LABEL')" class="col-5"
                                      label-for="input-title" style="width : 50%">
                            <b-form-input
                                id="input-title"
                                v-model="lesson.title"
                                :placeholder="$t('LESSON.TITLE.INPUT')"
                                required
                                type="text"
                            >
                            </b-form-input>
                        </b-form-group>

                        <div class="col" style="width:25%; text-align: left">
                            <b-form-checkbox id="linear" v-model="lesson.linear" name="linear" switch>
                                {{ $t('LESSON.LINEAR.FALSE.LABEL') }}
                            </b-form-checkbox>
                            <p class="text-muted text-left">
                                {{ $t('LESSON.LINEAR.FALSE.INFO') }}
                            </p>
                        </div>
                        <span class="col"></span>
                    </div>
                    <div class="d-flex flew-row justify-content-between align-items-center">
                        <div class="col-3" style="width:25%; text-align: left">
                            <div class="d-flex align-items-center justify-content-center">
                            <span v-if="lesson.media" class="svg-icon svg-icon-md mr-4" style="cursor: pointer"
                                  @click="downloadSummary(lesson.media)">
                                <inline-svg src="/media/svg/files/pdf.svg"></inline-svg>
                            </span>
                                <span v-else class="svg-icon svg-icon-md mr-4">
                                <inline-svg src="/media/svg/files/pdf.svg"></inline-svg>
                            </span>
                                <div v-if="lesson.media_id || lesson.synthese" style="cursor: pointer"
                                     @click="downloadSummary(lesson.media)">
                                    <p class="m-0">{{ media_name }}</p>
                                </div>
                                <div v-else>
                                    <p class="m-0">Pas de fiche synthèse ajoutée</p>
                                </div>
                            </div>
                            <b-form-group id="input-group-synthese"
                                          label-for="input-title">
                                <label v-if="!lesson.media_id && !lesson.synthese"
                                       class="mt-4 btn btn-primary btn-md btn-block"
                                       for="synthese">Ajouter
                                    une fiche synthèse (.pdf)</label>
                                <label v-else class="mt-4 btn btn-primary btn-md btn-block" for="synthese">Modifier la
                                    fiche synthèse (.pdf)</label>
                                <input id="synthese" ref="lesson.synthese" accept="application/pdf" name="synthese"
                                       style="display:none"
                                       type="file" @change="handleFileUpload"/>
                            </b-form-group>
                        </div>
                        <span class="col"></span>
                        <div v-if="this.classroom" class="col-5"
                             style="width:35%; text-align: left">
                            <div class="d-flex align-items-center">
                                <label for="min_duration">{{ $t('LESSON.MIN_DURATION.LABEL') }}</label>
                                <!--                                min_duration en eput pas être négatif-->
                                <b-form-input id="min_duration" v-model="lesson.min_duration" class="w-25 ml-5"
                                              min="1" name="min_duration" type="number"></b-form-input>
                            </div>
                            <p class="text-muted text-left mt-2">
                                {{ $t('LESSON.MIN_DURATION.INFO') }}
                            </p>
                        </div>
                        <span class="col"></span>
                    </div>
                    <b-form-group id="input-group-description" :label="$t('LESSON.DESCRIPTION.LABEL')"
                                  label-for="input-description">
                        <b-form-textarea
                            id="input-description"
                            v-model="lesson.description"
                            :placeholder="$t('LESSON.DESCRIPTION.INPUT')"
                            max-rows="6"
                            required
                            rows="4"
                            type="text"
                        >
                        </b-form-textarea>
                    </b-form-group>
                </div>
                <div class="mt-5 text-center">
                    <b-button class="mx-5 btn-lg" variant="primary" @click="Update()">
                        {{ $t('CONTROLLERS.UPDATE') }}
                    </b-button>
                    <b-button variant="danger" @click="Reset()">
                        {{ $t('CONTROLLERS.ERASE') }}
                    </b-button>
                </div>
            </b-form>
            <!--end::Form-->
        </b-skeleton-wrapper>
        <div
            v-if="submit"
            class="d-flex flex-column justify-content-around align-items-center"
            style="position:absolute;top:0;left:0;width:100%;height:100%;z-index:100;background-color: #ffffff9e;backdrop-filter: blur(5px)"
        >
            <div class="d-flex flex-column justify-content-around align-items-center" style="z-index:200;">
                <b-spinner style="width: 3rem; height: 3rem;" variant="primary"/>
                <p class="text-primary py-2 my-2">Enregistrement en cours ...</p>
            </div>
        </div>
    </div>
    <!--end::Dashboard-->
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {LIST_LESSON} from '@/core/services/store/api/lesson.service';
import {mapGetters} from 'vuex';
import ApiService from '@/core/services/api.service';
import i18n from '@/core/plugins/vue-i18n.js';
import {LIST_CLASSE} from "../../../core/services/store/api/classe.service";

export default {
    name: 'admin-form-edit-lesson',
    components: {},
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{title: i18n.t('MENU.DASHBOARD')}]);
        if (this.$route.params.id) {
            this.$store.dispatch(LIST_LESSON, this.$route.params.id).then(() => {
                this.lesson.linear ? (this.lesson.linear = true) : (this.lesson.linear = false);
                this.title = this.lesson.title;
                this.min_duration = this.lesson.min_duration;
                if (this.lesson.media) {
                    let urlParts = this.lesson.media.split('/');
                    this.media_name = urlParts.pop();
                }
                this.$store.dispatch(LIST_CLASSE, this.lesson.classe_id).then((result) => {
                    if (result.data.classroom === 1) {
                        this.classroom = true;
                    }
                })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        } else {
            this.$router.push({name: 'admin/dashboard'});
        }
    },
    computed: {
        ...mapGetters({lesson: 'getLesson'})
    },
    data() {
        return {
            loading: true,
            show: true,
            submit: false,
            title: null,
            min_duration: null,
            classroom: false,
            media_name: null
        };
    },
    methods: {
        Reset() {
            this.lesson.title = '';
            this.lesson.description = '';
            this.lesson.linear = false;
            this.lesson.min_duration = null;
            this.lesson.synthese = null;
            this.lesson.id = null;
            this.ReloadLesson();
        },
        downloadSummary(url) {
            window.open(url, 'blank')
        },
        handleFileUpload(event) {
            this.lesson.synthese = event.target.files[0];
            this.media_name = event.target.files[0].name;
            this.$forceUpdate();
        },
        Update() {
            this.submit = true;
            let data = new FormData();
            data.append('title', this.lesson.title);
            data.append('description', this.lesson.description);
            data.append('linear', this.lesson.linear === 'true' || this.lesson.linear === true);
            data.append('synthese', this.lesson.synthese);
            data.append('min_duration', this.lesson.min_duration);
            ApiService.post(process.env.VUE_APP_API_URL + '/academy/lessons/' + this.lesson.id, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(result => {
                    if (result.data) {
                        this.submit = false;
                        this.$router.push({
                            name: 'lessonItems/editor',
                            params: {id: this.lesson.id}
                        });
                    }
                })
                .catch(error => {
                    this.errorMessage = error.message;
                });
        },
        ReloadLesson() {
            this.loading = true;
            this.$store.dispatch(LIST_LESSON, this.$route.params.id).then(() => {
                this.lesson.linear ? (this.lesson.linear = true) : (this.lesson.linear = false);
                this.lesson.min_duration = this.min_duration;
                this.$store.dispatch(LIST_CLASSE, this.lesson.classe_id).then((result) => {
                    if (result.data.classroom === 1) {
                        this.classroom = true;
                    }
                })
                    .finally(() => {
                        this.loading = false;
                    });
                this.$forceUpdate();
            });

        }
    }
};
</script>
<style scoped>
.nl2br {
    max-height: 45%;
    overflow-y: scroll;
    text-align: left;
    padding-right: 2px;
}

.nl2brLesson {
    max-height: 7em;
    overflow-y: scroll;
    text-align: left;
    padding-right: 2px;
}
</style>
